import { Component, OnInit, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SessionIDService } from '@carwash-project/modules/core';
import { AuthActions } from '@carwash-project/modules/data-access/auth';
import { UI } from '@carwash-project/modules/ui';
import { UTILS } from '@carwash-project/modules/utils';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { StateClear } from 'ngxs-reset-plugin';
import { v4 as uuid } from 'uuid';

@Component({
  standalone: true,
  imports: [RouterModule, UI, UTILS],
  selector: 'carwash-project-root',
  template: `<router-outlet /> <ui-alerts /> `,
  styles: ``,
})
export class AppComponent implements OnInit {
  private readonly store = inject(Store);
  private readonly actions = inject(Actions);
  private readonly router = inject(Router);
  public title = 'carwash-project';

  constructor(private readonly sessionIDService: SessionIDService) {
    const sessionID = uuid();
    this.store.dispatch(new AuthActions.SessionID(sessionID));
    sessionIDService.setSessionID(sessionID);
  }

  public ngOnInit(): void {
    this.actions.pipe(ofActionDispatched(AuthActions.Logout)).subscribe(() => {
      this.router.navigate(['auth', 'main', 'login']);
      this.store.dispatch(new StateClear());
    });
  }
}
