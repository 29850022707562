import { EnvironmentModel } from "@carwash-project/modules/core";

export const environment:EnvironmentModel = {
    production: true,
    bugTracking: true,
    lcStorageKey:'carwash-state',
    urlApi:'https://carwash.dev.lumationsuite.com/',
    orgID:'1snn5n9w',
    keyEncrypt:'07r{I=-amn4?)FF5@Po)',
    siteKey:'6LeZa9gpAAAAAAFQcd0g4ko8B93F-TlXFdOkUoPH'
};